<template>
    <div>
        <div v-if="user">
            <v-container>
                <v-row dense>

                    <v-col v-for="card in cards" :key="card.title" cols="12" md="6">
                        <v-card :to="card.route" class="mx-auto" :title="$t(card.title)">
                            <template v-slot:prepend>
                                <v-avatar rounded="0" size="60">
                                    <v-img :src="getIcon(card.icon)" class="my-3" contain height="200" />
                                </v-avatar>
                            </template>
                            <template v-slot:append>
                                <v-avatar color="yellow-darken-1">
                                    {{ card.stat }}
                                </v-avatar>
                            </template>

                            <v-card-text>
                                {{ $t(card.subtitle) }}

                            </v-card-text>
                        </v-card>
                    </v-col>

                </v-row>
            </v-container>
        </div>

        <div v-else>
            Loading...
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import marketService from '@/utils/markets';

export default {
    name: 'DashboardHome',
    data() {
        return {
            cards: [
                { title: 'mainCards.marketInfoController', subtitle: 'mainCards.marketInfoControllerSubtitle', route: '/dashboard/marketinfo', icon: 'market.png', statLabel: 'Number of Products', stat: 0 },
                { title: 'mainCards.marketCategorieController', subtitle: 'mainCards.marketCategorieControllerSubtitle', route: '/dashboard/marketCategorie', icon: 'menu.png', statLabel: 'Number of Categories', stat: 0 },
                { title: 'mainCards.marketProductController', subtitle: 'mainCards.marketProductControllerSubtitle', route: '/dashboard/markertproduct', icon: 'add-to-cart.png', statLabel: 'Number of Products', stat: 0 },
                { title: 'mainCards.marketSliderController', subtitle: 'mainCards.marketSliderControllerSubtitle', route: '/dashboard/markertslide', icon: 'carousel.png', statLabel: 'Number of Sliders', stat: 0 },
                { title: 'mainCards.userController', subtitle: 'mainCards.userControllerSubtitle', route: '/dashboard/profile', icon: 'man.png' }
            ]
        };
    },
    computed: {
        ...mapGetters(['getUser']),
        user() {
            return this.getUser;
        }
    },
    methods: {
        ...mapActions(['fetchUser', 'logout', 'fetchProductsCount', 'fetchCategoriesCount', 'fetchSlidersCount']),
        async handleLogout() {
            try {
                await this.logout();
                this.$router.push('/dashboard/login');
            } catch (error) {
                console.error('Logout failed:', error);
            }
        },
        getIcon(iconName) {
            try {
                return require(`@/assets/icons/${iconName}`);
            } catch (e) {
                console.error(`Icon not found: ${iconName}`, e);
                return '';
            }
        },
        async fetchStats() {
            try {
                const marketId = this.user.marketId;
                const response = await marketService.getMarketById(marketId);
                const marketData = response.data;

                this.cards[0].stat = marketData.products.length;
                this.cards[1].stat = marketData.categories.length;
                this.cards[2].stat = marketData.products.length;
                this.cards[3].stat = marketData.sliders.length;
            } catch (error) {
                console.error('Failed to fetch stats:', error);
            }
        }
    },
    created() {
        if (!this.user) {
            this.fetchUser().catch(() => {
                this.logout();
                this.$router.push('/dashboard/login');
            });
        } else {
            this.fetchStats();
        }
    }
};
</script>

<style scoped>
.card {
    margin-top: 20px;
}
</style>
