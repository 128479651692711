<template>
    <v-container>
        <div>
            <v-row class="justify-space-between mb-5">
                <v-col cols="12" sm="12" md="4" lg="4" xl="4" xxl="4">
                    <div class="text-h5 pa-2">All Users</div>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4" xxl="4">
                    <v-text-field v-model="search" append-inner-icon="mdi-magnify" density="compact" label="Search"
                        variant="solo" hide-details single-line ></v-text-field>
                </v-col>
            </v-row>
            <v-spacer></v-spacer>

            <v-data-table v-if="isAdmin" :headers="headers" :items="filteredUsers" item-key="id">
                <template v-slot:item="props">
                    <tr>
                        <td>{{ props.item.id }}</td>
                        <td>{{ props.item.email }}</td>
                        <td>{{ props.item.role }}</td>
                        <td>{{ props.item.marketId }}</td>
                        <td>
                            <v-icon v-if="props.item.activated == true" color="success" class="me-2" size="small" @click="disactiveUserDialog(props.item)">
                                mdi-account-check 
                            </v-icon>
                            <v-icon v-if="props.item.activated == false" color="error" size="small" @click="activeUserDialog(props.item)">
                                mdi-account-cancel
                            </v-icon>
                        </td>
                        <td>
                            <v-icon class="me-2" size="small" @click="editUser(props.item)">
                                mdi-pencil
                            </v-icon>
                            <v-icon color="error" size="small" @click="confirmDeleteUser(props.item)">
                                mdi-delete
                            </v-icon>
                        </td>
                    </tr>
                </template>
            </v-data-table>

            <!-- Edit User Dialog -->
            <v-dialog v-model="editDialog" max-width="500px">
                <v-card>
                    <v-card-title>Edit User</v-card-title>
                    <v-card-text>
                        <v-form ref="form">
                            <v-text-field v-model="editedUser.email" label="Email"></v-text-field>
                            <v-text-field v-model="editedUser.role" label="Role"></v-text-field>
                            <v-text-field v-model="editedUser.marketId" label="Market ID"></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="primary" @click="saveUser">Save</v-btn>
                        <v-btn @click="cancelDialog">Cancel</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- Delete User Confirmation Dialog -->
            <v-dialog v-model="deleteDialog" max-width="500px">
                <v-card>
                    <v-card-title>Confirm Delete</v-card-title>
                    <v-card-text>Are you sure you want to delete this user?</v-card-text>
                    <v-card-actions>
                        <v-btn color="error" @click="deleteUser">Delete</v-btn>
                        <v-btn @click="cancelDialog">Cancel</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- Active user Dialog -->
            <v-dialog v-model="activeDialog" max-width="500px">
                <v-card>
                    <v-card-title>Confirm Active</v-card-title>
                    <v-card-text>Are you sure you want to Active this user?</v-card-text>
                    <v-card-actions>
                        <v-btn color="success" @click="activeUser">Active</v-btn>
                        <v-btn @click="cancelDialog">Cancel</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
                        <!-- disActive user Dialog -->
                        <v-dialog v-model="disActiveUsersDialog" max-width="500px">
                <v-card>
                    <v-card-title>Confirm diaActive</v-card-title>
                    <v-card-text>Are you sure you want to dis Active this user?</v-card-text>
                    <v-card-actions>
                        <v-btn color="success" @click="disActiveUser">disActive</v-btn>
                        <v-btn @click="cancelDialog">Cancel</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import authService from '@/utils/auth';

export default {
    name: 'allUsers',
    computed: {
        ...mapGetters(['getUser', 'myusers']),
        isAdmin() {
            return this.getUser && this.getUser.role === 'admin';
        },
        filteredUsers() {
            if (!this.search) {
                return this.users;
            }
            return this.users.filter((user) => {
                return (
                    user.email.toLowerCase().includes(this.search.toLowerCase()) ||
                    user.role.toLowerCase().includes(this.search.toLowerCase()) ||
                    String(user.id).includes(this.search) ||
                    String(user.marketId).includes(this.search)
                );
            });
        },
    },
    methods: {
        ...mapActions(['getallUsers']),
        editUser(user) {
            this.editedUser = { ...user };
            this.editDialog = true;
        },
        async saveUser() {
            try {
                await authService.updateUser(this.editedUser.id, this.editedUser);
                this.editDialog = false;
                this.fetchUsers();
            } catch (error) {
                console.error('Failed to update user:', error);
            }
        },

        confirmDeleteUser(user) {
            this.userToDelete = user;
            this.deleteDialog = true;
        },
        activeUserDialog(user){
            this.activeDialog= true;
            this.userToActivate = user.id;
        },
        disactiveUserDialog(user){
            this.disActiveUsersDialog= true;
            this.userToActivate = user.id;
        },
        async deleteUser() {
            try {
                await authService.deleteUser(this.userToDelete.id);
                this.deleteDialog = false;
                this.fetchUsers();
            } catch (error) {
                console.error('Failed to delete user:', error);
            }
        },
        cancelDialog() {
            this.editDialog = false;
            this.deleteDialog = false;
            this.activeDialog = false;
            this.disActiveUsersDialog = false;
        },
        async activeUser(){
            try {
                await authService.activateUser(this.userToActivate);
                this.activeDialog = false;
                this.fetchUsers();
            } catch (error) {
                console.log(error);
            }
        },
        async disActiveUser(){
            try {
                await authService.disactivateUser(this.userToActivate);
                console.log(this.userToActivate);
                this.disActiveUsersDialog = false;
                this.fetchUsers();
            } catch (error) {
                console.log(error);
            }
        },
        async fetchUsers() {
            try {
                const response = await authService.myusers();
                this.users = response.data;
            } catch (error) {
                console.error('Failed to load users:', error);
            }
        },
    },
    async created() {
        try {
            // Fetch the current user information
            await this.$store.dispatch('fetchUser');

            // Get the user from Vuex state
            const user = this.$store.getters.getUser;

            if (user && user.role === 'admin') {
                // If user is admin, fetch users
                await this.fetchUsers();
            } else {
                console.log('User is not an admin. Cannot fetch users.');
            }
        } catch (error) {
            console.error('Failed to initialize component:', error);
        }
    },
    data() {
        return {
            headers: [
                { title: 'ID', value: 'id' },
                { title: 'Email', value: 'email' },
                { title: 'Role', value: 'role' },
                { title: 'Market ID', value: 'marketId' },
                { title: 'Active', value: 'active' },
                { title: 'Actions', value: 'actions' },
            ],
            users: [],
            editDialog: false,
            deleteDialog: false,
            userToDelete: null,
            activeDialog: false,
            disActiveUsersDialog: false,
            userToActivate: null,
            editedUser: {
                id: '',
                email: '',
                role: '',
                marketId: '',
            },
            search: '',
        };
    },
};
</script>

<style scoped>
.card {
    margin-top: 20px;
}
</style>