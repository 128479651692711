<template>
    <v-app-bar class="navbar" app>
      <template v-slot:prepend>
        <v-app-bar-nav-icon @click.stop="rail = !rail"></v-app-bar-nav-icon>
      </template>
      <v-app-bar-title>ITLab</v-app-bar-title>
      <v-spacer></v-spacer>
      <v-btn @click="toggleTheme" prepend-icon="mdi-theme-light-dark" title=""></v-btn>
      <v-btn @click="changeLocale('en')" title="EN"><v-icon icon="mdi-alpha-e" size="x-large"></v-icon></v-btn>
      <v-btn @click="changeLocale('ar')" title="AR"><v-icon icon="mdi-abjad-arabic" size="large"></v-icon></v-btn>
    </v-app-bar>
    <v-navigation-drawer class="navbar" v-model="drawer" :rail="rail" permanent @click="rail = !rail">
      <v-list-item :prepend-avatar='require(`../assets/icons/man.png`)' title="Muqtada Najm" nav>
        <template v-slot:append>
          <v-btn icon="mdi-chevron-left" variant="text" @click.stop="rail = !rail"></v-btn>
        </template>
      </v-list-item>
      <v-divider></v-divider>
      <v-list density="compact" nav @click.stop="rail = true">
        <v-list-item v-for="link in filteredLinks" :key="link.text" :prepend-icon="link.icon" :title="$t(link.text)" router :to="link.route"></v-list-item>
        <v-list-item prepend-icon="mdi-exit-to-app" :title="$t('logout')" value="Logout" @click="handleLogout"></v-list-item>
      </v-list>
    </v-navigation-drawer>
  </template>
  
  <script setup>
  import { ref } from 'vue';
  import { useTheme } from 'vuetify';
  import { useLocale } from 'vuetify';
  import { useI18n } from 'vue-i18n';
  const { locale } = useI18n();
  
  const { current } = useLocale();
  const drawer = ref(true);
  const rail = ref(true);
  const theme = useTheme();
  
  function changeLocale(locales) {
    current.value = locales;
    locale.value = locales;
    localStorage.setItem('locale', locales); // حفظ اللغة في localStorage
  }
  
  const toggleTheme = () => {
    const newTheme = theme.global.current.value.dark ? 'light' : 'dark';
    theme.global.name.value = newTheme;
    localStorage.setItem('theme', newTheme); // حفظ إعدادات الثيم في localStorage
  };
  </script>
  
  <script>
  import { mapGetters, mapActions } from 'vuex';
  
  export default {
    data() {
      return {
        drawer: true,
        rail: false,
        links: [
          { icon: 'mdi mdi-home', text: 'home', route: '/dashboard/home' },
          { icon: 'mdi mdi-store-edit', text: 'marketInformation', route: '/dashboard/marketinfo' },
          { icon: 'mdi-tag-multiple', text: 'marketCategorie', route: '/dashboard/marketCategorie' },
          { icon: 'mdi-package-variant', text: 'marketProduct', route: '/dashboard/markertproduct' },
          { icon: 'mdi-view-carousel', text: 'marketSlider', route: '/dashboard/markertslide' },
          { icon: 'mdi-account', text: 'profile', route: '/dashboard/profile' },
          { icon: 'mdi-account-group', text: 'users', route: '/dashboard/admin/users' },
          { icon: 'mdi-store-settings-outline', text: 'markets', route: '/dashboard/admin/markets' },
        ],
      };
    },
  
    name: 'dashboardNavebar',
  
    computed: {
      ...mapGetters(['getUser']),
      filteredLinks() {
        if (this.getUser && this.getUser.role === 'admin') {
          return this.links;
        }
        return this.links.filter(link => link.text !== 'users' && link.text !== 'markets');
      },
    },
  
    methods: {
      ...mapActions(['fetchUser', 'logout']),
      async handleLogout() {
        try {
          await this.logout();
          this.$router.push('/dashboard/login');
        } catch (error) {
          console.error('Logout failed:', error);
        }
      },
    },
  
    async created() {
      try {
        await this.fetchUser();
      } catch {
        this.logout();
        this.$router.push('/dashboard/login');
      }
    },
  };
  </script>
  
  <style scoped></style>
  