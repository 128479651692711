// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
import colors from 'vuetify/util/colors'
//import store from '../store/index.js';
import ar from '../locales/ar';
import en from '../locales/en';
import { fa } from "vuetify/iconsets/fa";
import { aliases, mdi } from "vuetify/lib/iconsets/mdi";
// make sure to also import the coresponding css
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
import "@fortawesome/fontawesome-free/css/all.css"; // Ensure your project is capable of handling css files

const savedTheme = localStorage.getItem('theme') || 'light';

export default createVuetify({
    theme: {
        defaultTheme: savedTheme,
        themes: {
            light: {
                primary: colors.red.darken1, // #E53935
                secondary: colors.red.lighten4, // #FFCDD2
                colors:{
                    myBackgroundColor: colors.grey.lighten4,
                }
            },
            dark: {
                primary: colors.red.darken1, // #E53935
                secondary: colors.red.lighten4, // #FFCDD2
                colors:{
                    myBackgroundColor: colors.grey.darken4,

                }
            },
        },
        dark:  savedTheme === 'dark',
    },
    icons: {
        defaultSet: 'mdi',
        aliases,
        sets: {
            mdi,
            fa,
        },
    },
    locale: {
        locale: 'en',
        fallback: 'en',
        messages: { ar, en },
        rtl: {
            ar: true,
        },
    },
})