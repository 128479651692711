<template>
    <v-app>
        <v-main>
            <v-container fluid fill-height align-center justify-center>
                <v-card class="mx-auto pa-12 pb-8" elevation="8" max-width="448" rounded="lg">
                    <v-card-title class="text-center"> Login Form</v-card-title>
                    <form ref="form" @submit.prevent="submitForm">
                        <div class="text-subtitle-1 text-medium-emphasis">Account</div>
                        <v-text-field v-model="email" density="compact" placeholder="Email address"
                            prepend-inner-icon="mdi-email-outline" variant="outlined"></v-text-field>
                        <v-text-field v-model="password" :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
                            :type="visible ? 'text' : 'password'" density="compact" placeholder="Enter your password"
                            prepend-inner-icon="mdi-lock-outline" variant="outlined"
                            @click:append-inner="visible = !visible"></v-text-field>
                        <v-btn type="submit" class="mt-4" color="primary" value="log in">
                            Login
                            <v-icon icon="mdi-login"></v-icon>
                        </v-btn>
                    </form>
                    <v-card-text v-if="loginError" class="error-message text-caption">
                        {{ loginError }} </v-card-text>
                </v-card>
            </v-container>
        </v-main>
    </v-app>
</template>


<script>
export default {
    name: 'LoginForm',
    data() {
        return {
            email: '',
            password: '',
            loginError: null,
            visible: false
        };
    },
    methods: {
        submitForm() {
            this.$emit('login', { email: this.email, password: this.password });
        }
    }
};
</script>

<style scoped>
.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 300px;
    margin: 0 auto;
}

.input-container {
    position: relative;
    margin-bottom: 10px;
}

.input-container input {
    padding: 8px 30px 8px 10px;
    /* Adjust padding to accommodate icon */
    width: 100%;
}

.input-container i {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    color: #aaa;
}

.login-form button {
    padding: 10px;
    width: 100%;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
}

.error-message {
    color: red;
    margin-top: 10px;
    text-align: center;
}
</style>