<template>
    <div class="main-container" >
      <navebardashboard></navebardashboard>
      <v-card class="content custom-class">
        <v-layout class="backgroundcolors ">
          <v-main class="main-content">
            <router-view></router-view> 
          </v-main>
        </v-layout>
      </v-card>
      <dashboardFooter class="fixed-footer"></dashboardFooter>
    </div>
  </template>
  
  <script setup>
  import dashboardFooter from '@/components/Footer.vue';
  import navebardashboard from '@/components/Navebar.vue';
  

  </script>
  
  <script>
export default {  
    data: () => ({
     }),
      mounted: () => ({
      })
  }
</script>
  <style scoped>
    .custom-class {
    background: rgb(var(--v-theme-myBackgroundColor));
  }
  .main-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .content {
    flex: 1;
    padding-bottom: 100px; 
  }
  
  .fixed-footer {
    position: fixed;
    padding: 0;
    bottom: 0;
    width: 100%;
    z-index: 1000;
  }
  </style>
  