<template>
    <div class="login-page">
      <LoginForm ref="loginForm" @login="login" />
    </div>
  </template>
  
  <script>
  import LoginForm from '@/components/LoginForm.vue';
  import { mapActions } from 'vuex';
//   import axios from 'axios';
  
  export default {
    name: 'DashboardLogin',
    components: {
      LoginForm
    },
    methods: {
      ...mapActions(['performLogin']),
      async login(credentials) {
        try {
          await this.performLogin(credentials);
          console.log('Logged in successfully');
          this.$router.push('/dashboard/home');
        } catch (error) {
          console.error('Login failed:', error);
          // Check if this.$refs.loginForm is defined before setting loginError
          if (this.$refs.loginForm) {
            if (error.response && error.response.data && error.response.data.error) {
              const errorMessage = error.response.data.error.message;
              if (errorMessage.includes('Invalid email or password')) {
                this.$refs.loginForm.loginError = 'Login failed. Email or Password not correct.';
              } else if (errorMessage.includes('Account is not activated. Please contact the admin.')) {
                this.$refs.loginForm.loginError = 'Account is not activated. Please contact the admin.';
              } else {
                this.$refs.loginForm.loginError = 'Login failed. Please check your credentials.';
              }
            } else {
              this.$refs.loginForm.loginError = 'Login failed. Please check your credentials.';
            }
          } else {
            console.error('LoginForm component not found.');
          }
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .login-page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f5f5f5;
  }
  </style>
  