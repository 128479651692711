import Profile from '@/views/dashboard/profile.vue';
import login from '@/views/dashboard/login.vue';
import MainDashboard from '@/views/dashboard/mainDashboard.vue';
import DashboardHome from '@/views/dashboard/home.vue';
import marketInfo from '@/views/dashboard/marketInfo.vue';
import marketCategorie from '@/views/dashboard/marketCategorie.vue';
import marketProduct from '@/views/dashboard/marketProduct.vue';
import MarketSlide from '@/views/dashboard/marketSlide.vue';
import Users from '@/views/dashboard/users.vue';
import Markets from '@/views/dashboard/markets.vue';
const dashboardRoutes = [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: MainDashboard,
    children: [
      {
        path: '',
        name: 'maindashboard',
        component: DashboardHome
      },
      {
        path: 'profile',
        name: 'userProfile',
        component: Profile,
        meta: { requiresAuth: true }
      },
      {
        path: 'home',
        name: 'dashboard-home',
        component: DashboardHome,
        meta: { requiresAuth: true }
      },
      {
        path: 'marketInfo',
        name: 'market-info',
        component: marketInfo,
        meta: { requiresAuth: true }
      },
      {
        path: 'marketCategorie',
        name: 'market-categorie',
        component: marketCategorie,
        meta: { requiresAuth: true }
      },
      {
        path: 'markertslide',
        name: 'market-slide',
        component: MarketSlide,
        meta: { requiresAuth: true }
      },
      {
        path: 'markertproduct',
        name: 'market-product',
        component: marketProduct,
        meta: { requiresAuth: true }
      },
      {
        path: 'admin/users',
        name: 'allUsers',
        component: Users,
        meta: { requiresAuth: true }
      },
      {
        path: 'admin/markets',
        name: 'allMarkets',
        component: Markets,
        meta: { requiresAuth: true }
      },
    ]
  },
  {
    path: '/dashboard/login',
    name: 'dashboard-login',
    component: login
  },
  {
    path: '',
    name: 'dashboard-main',
    component: login
  }
];

export default dashboardRoutes;
