<template>
    <v-footer  class="d-flex flex-column ">
      <div class="bg-indigo d-flex w-100 align-center px-10">

        <div class=" px-4 py-2  text-center w-80">
        {{ new Date().getFullYear() }} — <strong>ITLab</strong>
      </div>
      <v-spacer></v-spacer>

        <v-btn
          v-for="icon in icons"
          :key="icon"
          :icon="icon"
          class="mx-4"
          size="small"
          variant="plain"
        ></v-btn>
      </div>
  
     
    </v-footer>
  </template>

<script setup>
const icons = [
  'mdi-facebook',
  'mdi-twitter',
  'mdi-linkedin',
  'mdi-instagram',
]
</script>

<script>
export default {
    name: 'dashboardFooter',
  data: () => ({
    icons: [
      'mdi-facebook',
      'mdi-twitter',
      'mdi-linkedin',
      'mdi-instagram',
    ],
  }),
}
</script>