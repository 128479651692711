import axios from 'axios';
import store from '@/store';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL || 'http://localhost:3000',
  headers: {
    'Content-Type': 'application/json'
  }
});

apiClient.interceptors.request.use(config => {
    const token = store.state.token || localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  });


export default {
  // تحميل ملف
  uploadFile(marketId, file) {
    const formData = new FormData();
    formData.append('file', file);

    return apiClient.post(`/files/${marketId}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(response => response.data); 
  },

  // تنزيل ملف
  downloadFile(marketId, fileName) {
    return apiClient.get(`/files/${marketId}/${fileName}`, {
      responseType: 'blob' 
    });
  },

  // الحصول على قائمة الملفات
  listFiles(marketId) {
    return apiClient.get(`/files/${marketId}`);
  }
};
