import axios from 'axios';
import store from '@/store';
const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL || 'http://localhost:3000',
  headers: {
    'Content-Type': 'application/json'
  }
});

apiClient.interceptors.request.use(config => {
    const token = store.state.token || localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  });

export default {
  login(credentials) {
    return apiClient.post('/users/login', credentials)
      .then(response => {
        const token = response.data.token;
        localStorage.setItem('token', token);
        store.commit('SET_TOKEN', token);
        console.log(localStorage.getItem('token'));
        console.log(store.state.token);
        
        apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        return response;
      });
  },
  getCurrentUser() {
    const token = localStorage.getItem('token');
    if (token) {
      apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
    return apiClient.get('/users/me');
  },
  signUpAdmin(data) {
    return apiClient.post('/users/sign-up/admin', data);
  },
  signUp(data) {
    return apiClient.post('/users/sign-up', data);
  },
  updateUser(userId, data) {
    return apiClient.patch(`/users/${userId}`, data);
  },
  deleteUser(userId) {
    return apiClient.delete(`/users/${userId}`), apiClient.delete(`/users/${userId}/user-credentials`);
  },
  getUser(userId) {
    return apiClient.get(`/users/${userId}`);
  },
  activateUser(userId) {
    return apiClient.patch(`/users/${userId}/activate`);
  },
  disactivateUser(userId) {
    return apiClient.patch(`/users/${userId}/disactivate`);
  },
  myusers() {
    return apiClient.get(`/users`);
  }
};
