<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="8">
        <template v-if="isAdmin">
          <h2>{{ $t('selectMarket') }}</h2>
          <v-select v-model="selectedMarketId" :items="markets" item-title="info.name" item-value="id"
            :label="$t('pleaseSelectAMarket')" @change="loadSliders" outlined></v-select>
        </template>
        <v-container>
          <template v-if="selectedMarketId || isAdmin">
            <h2>{{ $t('manageSliders') }}</h2>
            <v-btn @click="addSliderDialog = true" color="primary" class="mb-4">
              {{ $t('addSlider') }}
            </v-btn>
            <v-row class="mt-2">
              <v-col v-for="slider in sliders" :key="slider.id" cols="12" md="4" class="mt-2">
                <v-card>
                  <v-img :src="apiUrl + slider.image" height="200px"></v-img>
                  <v-card-actions>
                    <v-btn @click="editSlider(slider)" color="primary">{{ $t('edit') }}</v-btn>
                    <v-btn @click="deleteSlider(slider.id)" color="error">{{ $t('delete') }}</v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </template>
          <template v-else>
            <p v-if="!isAdmin">{{ $t('noPermissionToEditMarketInformation') }}</p>
            <p v-else>{{ $t('Loading...') }}</p>
          </template>
        </v-container>
      </v-col>
    </v-row>

    <!-- Add/Edit Slider Dialog -->
    <v-dialog v-model="addSliderDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ editMode ? $t('editSlider') : $t('addSlider') }}</span>
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent="editMode ? updateSlider() : createSlider()">
            <v-file-input  :label="$t('sliderImage')" accept="image/*" prepend-icon="mdi-image" @input="handleImageUpload"></v-file-input>

            <v-btn class="mx-2" type="submit" color="primary">
              {{ editMode ? $t('update') : $t('create') }}
            </v-btn>
            <v-btn class="mx-2" @click="closeDialog">{{ $t('cancel') }}</v-btn>
            <v-progress-circular v-if="isUploading" indeterminate color="primary"></v-progress-circular>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import marketSlideService from '@/utils/marketslide';
import fileUploadService from '@/utils/fileupload';

export default {
  data() {
    return {
      selectedMarketId: null,
      sliders: [],
      addSliderDialog: false,
      editMode: false,
      sliderForm: {
        image: null,
      },
      isUploading: false,
    };
  },
  computed: {
    ...mapGetters(['getMarketInfo', 'getUser', 'getMarkets']),
    marketInfo() {
      return this.getMarketInfo;
    },
    isAdmin() {
      return this.getUser && this.getUser.role === 'admin';
    },
    markets() {
      return this.getMarkets.map(market => ({
        ...market,
        'info.name': market.info.name,
      }));
    },
    apiUrl() {
      return process.env.VUE_APP_API_URL + '/';
    },
  },
  methods: {
    ...mapActions(['fetchUser', 'fetchAllMarkets']),
    async loadSliders() {
      try {
        const response = await marketSlideService.getSliders(this.selectedMarketId);
        this.sliders = response.data;
      } catch (error) {
        console.error('Failed to fetch sliders:', error);
      }
    },
    editSlider(slider) {
      this.editMode = true;
      this.sliderForm = { ...slider };
      this.addSliderDialog = true;
    },
    async createSlider() {
      try {
        const formData = new FormData();
        formData.append('image', this.sliderForm.image);
        await marketSlideService.createSlider(this.selectedMarketId, formData);
        this.loadSliders();
        this.closeDialog();
      } catch (error) {
        console.error('Failed to create slider:', error);
      }
    },
    async updateSlider() {
      try {
        const formData = new FormData();
        formData.append('image', this.sliderForm.image);
        await marketSlideService.updateSlider(this.selectedMarketId, this.sliderForm.id, formData);
        this.loadSliders();
        this.closeDialog();
      } catch (error) {
        console.error('Failed to update slider:', error);
      }
    },
    async deleteSlider(sliderId) {
      try {
        await marketSlideService.deleteSlider(this.selectedMarketId, sliderId);
        this.loadSliders();
      } catch (error) {
        console.error('Failed to delete slider:', error);
      }
    },
    closeDialog() {
      this.addSliderDialog = false;
      this.editMode = false;
      this.sliderForm = {
        image: null,
      };
    },
    async handleImageUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.isUploading = true;
        try {
          const response = await fileUploadService.uploadFile(this.selectedMarketId, file);
          this.sliderForm.image = response.files[0].path;
        } catch (error) {
          console.error('Failed to upload image:', error);
        } finally {
          this.isUploading = false;
        }
      }
    },
  },
  async created() {
    try {
            await this.$store.dispatch('fetchUser');
            const user = this.getUser;
            if (user && user.marketId) {
                this.selectedMarketId = user.marketId;
                await this.loadSliders();
            } else if (this.isAdmin) {
                await this.fetchAllMarkets();
            } else {
                console.log('User does not have permission to manage Slide.');
            }
        } catch (error) {
            console.error('Failed to initialize component:', error);
        }
  },
  watch: {
    selectedMarketId(newId) {
      if (newId) {
        this.loadSliders();
      }
    },
  },
};
</script>

<style scoped>
</style>
