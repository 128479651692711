import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import '@mdi/font/css/materialdesignicons.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css'

loadFonts()

import { createI18n } from 'vue-i18n';
import ar from './locales/ar';
import en from './locales/en';

const messages = {
  ar,
  en,
};
const savedLocale = localStorage.getItem('locale') || 'en';

const i18n = createI18n({
    legacy: false,
  locale: savedLocale, // اللغة الافتراضية
  fallback: 'en',
  fallbackLocale: 'en',
  messages,
});


createApp(App)
.use(i18n)
  .use(router)
  .use(store)
  .use(vuetify)
  .mount('#app');


  