import axios from 'axios';
import store from '@/store';

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_API_URL || 'http://localhost:3000',
    headers: {
        'Content-Type': 'application/json'
    }
});

apiClient.interceptors.request.use(config => {
    const token = store.state.token || localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  });
export default {
    getMarketInfo(marketId) {
        return apiClient.get(`/markets/${marketId}/info`);
    },
    updateMarketInfo(marketId, data) {
        return apiClient.patch(`/markets/${marketId}/info`, data);
    },
    getMarketCount() {
        return apiClient.get('/markets/count');
    },
    updateMarket(id, data) {
        return apiClient.put(`/markets/${id}`, data);
    },
    patchMarket(id, data) {
        return apiClient.patch(`/markets/${id}`, data);
    },
    getMarketById(id) {
        return apiClient.get(`/markets/${id}`);
    },
    deleteMarket(id) {
        return apiClient.delete(`/markets/${id}`);
    },
    createMarket(data) {
        return apiClient.post('/markets', data);
    },
    patchMarkets(data) {
        return apiClient.patch('/markets', data);
    },
    getAllMarkets() {
        return apiClient.get('/markets');
    }
};
