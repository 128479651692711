const ar = {
    name:"الاسم",
    email: "البريد الإلكتروني",
    role: "الدور",
    id: "المعرف",
    open: "فتح",
    close: "غلق",
    cancel: "إلغاء",
    update: "تحديث",
    create: "إنشاء",
    noDataText: "لا توجد بيانات",
    save: "حفظ",
    edit: "تعديل",
    delete: "حذف",
    marketId: "معرف المتجر",
    itemsPerPageText: "عنصر في الصفحة",
    description:"الوصف",
    dataFooter: {
      lastPage: "الصفحة الأخيرة",
      pageText: "التنقل بين الصفحات",
      itemsPerPageText: "عنصر في الصفحة",
      nextPage: "الصفحة التالية",
      prevPage: "الصفحة السابقة",
      firstPage: "الصفحة الأولى",
      itemsPerPageAll: "العناصر في كل صفحة",
    },
    
    mainCards: {
      marketCategorieController: "قائمة أصناف المتجر",
      marketInfoController: "معلومات المتجر",
      marketProductController: "قائمة منتجات المتجر",
      marketSliderController: "قائمة شرائح المتجر",
      userController: "الملف الشخصي",
      
      marketCategorieControllerSubtitle: "تستطيع من هنا إدارة الأصناف الخاصة بالمتجر",
      marketInfoControllerSubtitle: "متحكم لإدارة المعلومات الخاصة بالمتجر",
      marketProductControllerSubtitle: "متحكم لإدارة المنتجات الخاصة بالمتجر",
      marketSliderControllerSubtitle: "متحكم لإدارة الشرائح الخاصة بالمتجر",
      userControllerSubtitle: "عرض الملف الشخصي",
    },
    
    home: "الرئيسية",
    marketInformation: "معلومات المتجر",
    marketProduct: "المنتجات",
    marketSlider: "الشرائح",
    marketCategorie: "الأصناف",
    users: "المستخدمين",
    markets: "المتاجر",
    user: "المستخدم",
    profile: "الملف الشخصي",
    logout: "تسجيل خروج",
    selectMarket: "اختر المتجر",
    pleaseSelectAMarket: "من فضلك اختر المتجر",
    addCategory: "إضافة صنف جديد",
    editCategory: "تعديل الصنف",
    addProduct:"إضافة منتج",
    editProduct: "تعديل المنتج",
    manageProducts:"إدارة المنتحجات",
    manageCategories: "إدارة الأصناف",
    manageSliders: "إدارة الشرائح",
    addSlider: "إضافة شريحة",
    editSlider: "تعديل الشريحة",
    allProducts:"جميع المنتجات",
    editMarketInformation: "تعديل معلومات المتجر",
    changeLogo: "تعديل الشعار",
    changeBackground: "تغيير الخلفية",
    selectLanguage: "اختر اللغة",
    selectCurrency: "اختر العملة",
    noPermissionToEditMarketInformation: "ليس لديك الصلاحية لتعديل معلومات المتجر.",
    marketInformationUpdatedSuccessfully:"تم تحديث معلومات المتجر بنجاح.",
    failedToUpdateMarketInformation:"خطأ لم يتم تحديث معلومات المتجر.",

    pagination: {
      ariaLabel: {
        root: "الجذر",
      },
    },
    input:{
        clear:"Clear",
        prependAction:"prepend Action",
        appendAction:"append Action",

    },

    categoryImage:"صورة الصنف",
    productImage:"صورة المنتج",
    sliderImage:"صورة الشريحة",
    image:"الصورة",
    nameArabic:'الاسم (عربي)',
    nameEnglish:'الاسم (انكليزي)',
    nameKurdish:'الاسم (كردي)',
    descriptionArabic:"الوصف (عربي)",
    descriptionEnglish:"الوصف (انكليزي)",
    descriptionKurdish:"الوصف (كردي)",

    productCount:'عدد المنتجات',
    actions:"الإجراءات",
    price:"السعر",
    price_s:"السعر (S)",
    price_m:"السعر (M)",
    price_l:"السعر (L)",
    singlePrice:"سعر واحد",

    itemVes:"عرض المنتج",
    category:"النصف",

  };
  
  export default ar;
  