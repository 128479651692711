<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" sm="8" md="6">
                <div v-if="currentUser">
                    <v-card>
                        <v-img :src="require('@/assets/icons/man.png')" aspect-ratio="1" class="white--text"
                            height="200px"></v-img>
                        <v-card-text> <v-icon icon="mdi-gmail"></v-icon> {{ $t("email") }}: {{ currentUser.email }}
                        </v-card-text>
                        <v-card-text> <v-icon icon="mdi-identifier"></v-icon> {{ $t("id") }}: {{ currentUser.id }}
                        </v-card-text>
                        <v-card-text> <v-icon icon="mdi-account-question"></v-icon> {{ $t("role") }}: {{ currentUser.role }}
                        </v-card-text>
                        <v-card-text> <v-icon icon="mdi-shopping-outline"></v-icon> {{ $t("marketId") }}: {{ currentUser.marketId }}
                        </v-card-text>
                        <v-card-actions>
                            <v-btn @click="handleLogout">Logout  <v-icon icon="mdi-exit-to-app"></v-icon></v-btn>
                        </v-card-actions>
                    </v-card>
                </div>
                <div v-else>
                    Loading...
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'UserProfile',
    computed: {
        ...mapGetters(['getUser']),
        currentUser() {
            return this.getUser;
        },
    },
    methods: {
        ...mapActions(['fetchUser', 'logout']),
        async handleLogout() {
            try {
                await this.logout();
                this.$router.push('/dashboard/login');
            } catch (error) {
                console.error('Logout failed:', error);
            }
        },
    },
    async created() {
        if (!this.currentUser) {
            try {
                await this.fetchUser();
            } catch {
                this.logout();
                this.$router.push('/dashboard/login');
            }
        }
    },
};
</script>

<style scoped>
.card {
    margin-top: 20px;
}
</style>