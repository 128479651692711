const en = {
    name:"Name",
    email: "Email",
    role: "Role",
    open: "Open",
    close: "Close",
    cancel: "Cancel",
    update: "Update",
    create: "Create",
    noDataText: "No data available",
    prependAction: "Prepend Action",
    save: "Save",
    edit: "Edit",
    delete: "Delete",
    itemsPerPageText: "Items per page",
    inputClear: "Clear",
    description:"Description",
    dataFooter: {
      lastPage: "Last Page",
      pageText: "Page",
      itemsPerPageText: "Items per page",
      nextPage: "Next Page",
      prevPage: "Previous Page",
      firstPage: "First Page",
      itemsPerPageAll: "All items per page",
    },
    
    mainCards: {
      marketCategorieController: "Market Category Controller",
      marketInfoController: "Market Info Controller",
      marketProductController: "Market Product Controller",
      marketSliderController: "Market Slider Controller",
      userController: "Profile",
      
      marketCategorieControllerSubtitle: "Controller for managing market categories",
      marketInfoControllerSubtitle: "Controller for managing market information",
      marketProductControllerSubtitle: "Controller for managing market products",
      marketSliderControllerSubtitle: "Controller for managing market sliders",
      userControllerSubtitle: "Show Your Profile",
    },
    
    home: "Home",
    marketInformation: "Market Information",
    marketProduct: "Market Product",
    marketSlider: "Market Slider",
    marketCategorie: "Market Category",
    users: "Users",
    markets: "Markets",
    user: "User",
    profile: "Profile",
    logout: "Logout",
    
    selectMarket: "Select Market",
    pleaseSelectAMarket: "Please select a market",
    addCategory: "Add Category",
    editCategory: "Edit Category",
    addProduct:"Add Product",
    editProduct: "Edit Product",
    manageProducts:"Manage Products",
    manageCategories: "Manage Categories",
    manageSliders: "Manage Sliders",
    addSlider: "Add Slider",
    editSlider: "Edit Slider",
    editMarketInformation: "Edit Market Information",
    allProducts:"all Products",

    changeLogo: "Change Logo",
    changeBackground: "Change Background",
    selectLanguage: "Select Language",
    selectCurrency: "Select Currency",
    noPermissionToEditMarketInformation: "You do not have permission to edit market information.",
    marketUpdatedSuccessfully:"Market updated successfully.",
    failedToUpdateMarket:"Failed to update market.",
    pagination: {
      ariaLabel: {
        root: "Root",
      },
    },
    input:{
        clear:"Clear",
        prependAction:"prepend Action",
        appendAction:"append Action",
    },

    categoryImage:"Category Image",
    productImage:"Product Image",
    sliderImage:"Slider Image",

    image:"Image",
    nameArabic:'Name (Arabic)',
    nameEnglish:'Name (English)',
    nameKurdish:'Name (Kurdish)',
    descriptionArabic:"Description (Arabic)",
    descriptionEnglish:"Description (English)",
    descriptionKurdish:"Description (Kurdish)",


    productCount:'Product Count',
    actions:"Actions",
    price:"Price",
    price_s:"Price (S)",
    price_m:"Price (M)",
    price_l:"Price (L)",
    
    singlePrice:"Single Price",
    itemVes:"itemVes",
    category:"Category",


  };
  
  export default en;
  