 <template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" md="8" class="text">
                <template v-if="isAdmin">
                    <h2>{{ $t('selectMarket') }}</h2>
                    <v-select v-model="selectedMarketId" :items="markets" item-title="info.name" item-value="id"
                    :label="$t('pleaseSelectAMarket')"  outlined></v-select>
                </template>
                <v-card variant="text">
                    <template v-if="marketInfo">
                        <h2>{{ $t('editMarketInformation') }}</h2>
                        <v-form @submit.prevent="updateMarketInformation">

                            
                            <v-card class="text mx-auto mb-5 py-10" color="surface-variant"
                                :image="apiUrl + marketInfo.background_image" max-height="340">
                                <template v-slot:actions>
                                    <v-avatar size="64" class="mx-auto mb-2">
                                        <v-img :src="apiUrl + marketInfo.logo" alt="Logo"
                                            style="width:100%; height: inherit;"></v-img>
                                    </v-avatar>
                                </template>
                            </v-card>

                            <div class="mb-5">
                                <input type="file" @change="handleLogoUpload" ref="logoUploader"
                                    style="display: none;" />
                                <v-btn @click="triggerLogoUpload" class="me-2 text-none" prepend-icon="mdi-plus"
                                    variant="outlined">
                                    {{$t("changeLogo")}}
                                </v-btn>
                                <input type="file" @change="handleBackgroundUpload" ref="backgroundUploader"
                                    style="display: none;" />
                                <v-btn @click="triggerBackgroundUpload" class="me-2 text-none" prepend-icon="mdi-plus"
                                    variant="outlined">
                                    {{$t("changeBackground")}}
                                </v-btn>
                            </div>
                            
                            <v-text-field  prepend-inner-icon="mdi-store" v-model="marketInfo.name" :label="$t('name')"></v-text-field>
                            
                            <p><v-icon>mdi-translate-variant</v-icon>  {{$t("selectLanguage")}} </p>
                            <div class="checkbox-group">
                                <v-checkbox v-for="language in availableLanguages" :key="language"
                                    v-model="marketInfo.languages" :label="language" :value="language"
                                    inline></v-checkbox>
                            </div>

                            <p><v-icon>mdi-wallet</v-icon>  {{$t("selectCurrency")}}</p>
                            <div class="checkbox-group">
                                <v-checkbox v-for="currency in currencyOptions" :key="currency.id"
                                    v-model="marketInfo.currency" :label="currency.name" :value="currency.id"
                                    inline></v-checkbox>
                            </div>

                            <v-textarea prepend-inner-icon="mdi-information-slab-circle-outline" v-model="marketInfo.description" :label="$t('description')" ></v-textarea>

                            <v-text-field prepend-inner-icon="mdi-facebook" v-model="marketInfo.social_links.facebook" label="Facebook"></v-text-field>
                            <v-text-field prepend-inner-icon="mdi-instagram" v-model="marketInfo.social_links.instagram" label="Instagram"></v-text-field>
                            <v-text-field prepend-inner-icon="mdi-send" v-model="marketInfo.social_links.telegram" label="Telegram"></v-text-field>
                            <v-text-field prepend-inner-icon="mdi-whatsapp" v-model="marketInfo.social_links.whatsapp" label="WhatsApp"></v-text-field>

                            <v-btn class="mb-2" type="submit" color="primary">{{$t("save")}}</v-btn>
                        </v-form>
                    </template>
                    <template v-else>
                        <p v-if="!isAdmin">{{ $t("noPermissionToEditMarketInformation") }}</p>
                    </template>
                </v-card>
            </v-col>
        </v-row>
        <v-snackbar v-model="successSnackbar" color="success">
            {{ $t("marketUpdatedSuccessfully") }}
        </v-snackbar>
        <v-snackbar v-model="errorSnackbar" color="error">
            {{ $t('failedToUpdateMarket') }}
        </v-snackbar>
    </v-container>
</template>


<script>
import { mapGetters, mapActions } from 'vuex';
import fileUploadService from '@/utils/fileupload';

export default {
    data() {
        return {
            selectedMarketId: null,
            availableLanguages: ['ar', 'en', 'ckb'],
            successSnackbar: false,
            errorSnackbar: false,

        };
    },
    computed: {
        ...mapGetters(['getMarketInfo', 'getUser', 'getMarkets']),
        marketInfo() {
            return this.getMarketInfo;
        },
        isAdmin() {
            return this.getUser && this.getUser.role === 'admin';
        },
        markets() {
            return this.getMarkets.map(market => ({
                ...market,
                'info.name': market.info.name // Ensure the structure is correct
            }));
        },
        currencyOptions() {
            return [
                { id: 'IQD', name: 'IQD' },
                { id: 'USD', name: 'USD' }
            ];
        },
        apiUrl() {
            return process.env.VUE_APP_API_URL + '/';
        },
        imageUrl() {
            return { backgroundImage: `url(${this.apiUrl + this.marketInfo.background_image})`, height: '200px', backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }
        }
    },
    methods: {
        ...mapActions(['fetchMarketInfo', 'updateMarketInfo', 'fetchAllMarkets']),
        async loadMarketInfo() {
            try {
                await this.fetchMarketInfo(this.selectedMarketId);
            } catch (error) {
                console.error('Failed to load market info:', error);
            }
        },
        async updateMarketInformation() {
            try {
                const dataToUpdate = {
                    name: this.marketInfo.name,
                    logo: this.marketInfo.logo,
                    background_image: this.marketInfo.background_image,
                    languages: this.marketInfo.languages,
                    currency: this.marketInfo.currency,
                    description: this.marketInfo.description,
                    social_links: this.marketInfo.social_links,
                };
                await this.updateMarketInfo({ marketId: this.selectedMarketId, data: dataToUpdate });
                console.log('Market information updated successfully!');
                this.successSnackbar = true;
                setTimeout(() => {
                    this.successSnackbar = false;
                }, 3000);
            } catch (error) {
                console.error('Failed to update market info:', error);
                this.errorSnackbar = true;
                setTimeout(() => {
                    this.errorSnackbar = false;
                }, 3000);
            }
        },
        triggerLogoUpload() {
            this.$refs.logoUploader.click();
        },
        triggerBackgroundUpload() {
            this.$refs.backgroundUploader.click();
        },
        async handleLogoUpload(event) {
            const file = event.target.files[0];
            if (file) {
                try {
                    const response = await fileUploadService.uploadFile(this.selectedMarketId, file);
                    this.marketInfo.logo = response.files[0].path; // حفظ مسار الملف
                    await this.updateMarketInformation();
                } catch (error) {
                    console.error('Failed to upload logo:', error);
                }
            }
        },
        async handleBackgroundUpload(event) {
            const file = event.target.files[0];
            if (file) {
                try {
                    const response = await fileUploadService.uploadFile(this.selectedMarketId, file);
                    this.marketInfo.background_image = response.files[0].path; // حفظ مسار الملف
                    await this.updateMarketInformation();
                } catch (error) {
                    console.error('Failed to upload background image:', error);
                }
            }
        },
    },
    async created() {
        try {
            await this.$store.dispatch('fetchUser');
            const user = this.getUser;
            if (user && user.marketId && user.role != 'admin') {
                this.selectedMarketId = user.marketId;
                console.log('User has selected marketId:', this.selectedMarketId);
                await this.loadMarketInfo();
            } else if (this.isAdmin) {
                await this.fetchAllMarkets();
            } else {
                console.log('User does not have permission to edit market information.');
            }
        } catch (error) {
            console.error('Failed to initialize component:', error);
        }
    },
    watch: {
        selectedMarketId(newId) {
            if (newId) {
                this.loadMarketInfo();
            }
        },
    }
};
</script>

<style scoped>
.image-upload-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 200px;
}

.checkbox-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.checkbox-group>.v-input--selection-controls__ripple {
    flex: 1 1 auto;
}
</style>


<style scoped>
.image-upload-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
}

.checkbox-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.checkbox-group>.v-input--selection-controls__ripple {
    flex: 1 1 auto;
}
</style> 




