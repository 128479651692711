<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" md="8">
                <template v-if="isAdmin">
                    <h2>{{ $t('selectMarket') }}</h2>
                    <v-select v-model="selectedMarketId" :items="markets" item-title="info.name" item-value="id"
                        :label="$t('pleaseSelectAMarket')" @change="loadProducts" outlined></v-select>
                </template>
                <v-container>
                    <template v-if="selectedMarketId || isAdmin">

                        <v-row>
                            <v-col>
                                <h2>{{ $t('manageProducts') }}</h2>
                                <v-btn @click="addProductDialog = true" color="primary" class="mb-4">
                                    {{ $t('addProduct') }}
                                </v-btn>
                            </v-col>
                            <v-col>
                                <v-select v-model="FilterProductBycategories" :items="filtercategories"
                                    item-title="name_ar" item-value="id" label="Category" required></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-data-table :headers="headers" :items="filteredProducts" item-key="id">
                                <template v-slot:item="props">
                                    <tr>
                                        <td>{{ props.item.id }}</td>
                                        <td>
                                            <v-avatar>
                                                <v-img :src="apiUrl + props.item.image" size="x-large"></v-img>
                                            </v-avatar>
                                        </td>
                                        <td>
                                            <p>EN: {{ props.item.name_en }}</p>
                                            <p>AR: {{ props.item.name_ar }}</p>
                                            <p>KU: {{ props.item.name_ku }}</p>
                                        </td>
                                        <td>
                                            <p v-if="props.item.single_price == 'true'">{{ props.item.price }}</p>
                                            <div v-else>
                                                <p>(S){{ props.item.price_s }}</p>
                                                <p>(M){{ props.item.price_m }}</p>
                                                <p>(L){{ props.item.price_l }}</p>
                                            </div>
                                        </td>
                                        <td>{{ props.item.itemVes }}</td>
                                        <td>
                                            <v-icon class="me-2" size="small"
                                                @click="editProduct(props.item)">mdi-pencil</v-icon>
                                            <v-icon color="error" size="small"
                                                @click="openDeleteDialog(props.item.id)">mdi-delete</v-icon>
                                        </td>
                                        <td>
                                            <v-btn color="success" icon @click="moveUp(props.item.id)">
                                                <v-icon>mdi-arrow-up</v-icon>
                                            </v-btn>
                                            <v-btn color="warning" icon @click="moveDown(props.item.id)">
                                                <v-icon>mdi-arrow-down</v-icon>
                                            </v-btn>
                                        </td>
                                    </tr>

                                </template>

                            </v-data-table>

                            <v-dialog v-model="deleteDialog" max-width="400">
                                <v-card>
                                    <v-card-title class="headline">تأكيد الحذف</v-card-title>
                                    <v-card-text>هل أنت متأكد أنك تريد حذف هذا العنصر؟</v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="blue darken-1" text @click="closeDeleteDialog">إلغاء</v-btn>
                                        <v-btn color="red darken-1" text @click="confirmDelete">حذف</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-row>








                    </template>
                    <template v-else>
                        <p v-if="!isAdmin">{{ $t('noPermissionToEditMarketInformation') }}</p>
                        <p v-else>{{ $t('Loading...') }}</p>
                    </template>
                </v-container>
            </v-col>
        </v-row>

        <!-- Add/Edit Product Dialog -->
        <v-dialog v-model="addProductDialog" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">{{ editMode ? $t('editProduct') : $t('addProduct') }}</span>
                </v-card-title>
                <v-card-text>
                    <v-form @submit.prevent="editMode ? updateProduct() : createProduct()">
                        <v-select v-model="productForm.categorie_id" :items="categories" item-title="name_ar"
                            item-value="id" :label="$t('category')" required></v-select>
                        <v-text-field v-model="productForm.name_en" :label="$t('nameEnglish')" required></v-text-field>
                        <v-text-field v-model="productForm.name_ar" :label="$t('nameArabic')" required></v-text-field>
                        <v-text-field v-model="productForm.name_ku" :label="$t('nameKurdish')" required></v-text-field>
                        <v-textarea v-model="productForm.description_en" :label="$t('descriptionEnglish')"
                            required></v-textarea>
                        <v-textarea v-model="productForm.description_ar" :label="$t('descriptionArabic')"
                            required></v-textarea>
                        <v-textarea v-model="productForm.description_ku" :label="$t('descriptionKurdish')"
                            required></v-textarea>
                        <v-switch v-model="productForm.single_price" :label="$t('singlePrice')"
                            color="primary"></v-switch>
                        <!-- single price -->
                        <v-text-field v-if="productForm.single_price" v-model="productForm.price" :label="$t('price')"
                            required></v-text-field>

                        <!-- Multi price -->
                        <v-text-field v-if="!productForm.single_price" v-model="productForm.price_s"
                            :label="$t('price_s')" required></v-text-field>
                        <v-text-field v-if="!productForm.single_price" v-model="productForm.price_m"
                            :label="$t('price_m')" required></v-text-field>
                        <v-text-field v-if="!productForm.single_price" v-model="productForm.price_l"
                            :label="$t('price_l')" required></v-text-field>
                        <v-switch v-model="productForm.itemVes" :label="$t('itemVes')" color="primary"></v-switch>
                        <v-file-input :label="$t('productImage')" accept="image/*" prepend-icon="mdi-image"
                            @input="handleImageUpload">
                        </v-file-input>

                        <v-btn class="mx-2" type="submit" color="primary">
                            {{ editMode ? $t('update') : $t('create') }}
                        </v-btn>
                        <v-btn class="mx-2" @click="closeDialog">{{ $t('cancel') }}</v-btn>
                        <v-progress-circular v-if="isUploading" indeterminate color="primary"></v-progress-circular>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import marketProductService from '@/utils/marketproduct';
import fileUploadService from '@/utils/fileupload';
import marketCategorieService from '@/utils/marketcategorie';

export default {

    data() {
        return {
            selectedMarketId: null,
            products: [],
            dragging: false,
            deleteDialog: false,
            itemToDelete: null,
            filteredProducts: [],
            filtercategories: [],
            categories: [],
            addProductDialog: false,
            editMode: false,
            productForm: {
                categorie_id: null,
                name_en: '',
                name_ar: '',
                name_ku: '',
                description_en: '',
                description_ar: '',
                description_ku: '',
                image: null,
                single_price: true,
                price: '',
                price_s: '',
                price_m: '',
                price_l: '',
                itemVes: true
            },
            headers: [

                { title: this.$t('id'), value: 'id' },
                { title: this.$t('image'), value: 'image' },
                { title: this.$t('name'), value: 'name_ar' },
                { title: this.$t('price'), value: 'price' },
                { title: this.$t('itemVes'), value: 'id' },
                { title: this.$t('actions'), value: 'actions' },
                { title: "تحريك", value: "moving" },

            ],
            FilterProductBycategories: null,
            isUploading: false
        };
    },
    computed: {
        ...mapGetters(['getMarketInfo', 'getUser', 'getMarkets']),
        marketInfo() {
            return this.getMarketInfo;
        },
        isAdmin() {
            return this.getUser && this.getUser.role === 'admin';
        },
        markets() {
            return this.getMarkets.map(market => ({
                ...market,
                'info.name': market.info.name // Ensure the structure is correct
            }));
        },
        apiUrl() {
            return process.env.VUE_APP_API_URL + '/';
        },
    },
    methods: {
        ...mapActions(['fetchUser', 'fetchAllMarkets']),
        async loadProducts() {
            try {
                const response = await marketProductService.getProducts(this.selectedMarketId);
                this.products = response.data;
                this.filterProductsByCategory();
            } catch (error) {
                console.error('Failed to fetch products:', error);
            }
        },
        editProduct(product) {
            this.editMode = true;
            this.productForm = {
                ...product,
                itemVes: product.itemVes === 'true',
                single_price: product.single_price === 'true'
            };
            this.addProductDialog = true;
        },
        getCategoryNameAr(categoryId) {
            const category = this.categories.find(cat => cat.id == categoryId);
            return category ? category.name_ar : '';
        },
        async loadCategories() {
            try {
                const response = await marketCategorieService.getCategories(this.selectedMarketId);
                this.categories = [...response.data];
                this.filtercategories = [{ id: null, name_ar: this.$t('allProducts') }, ...response.data];
            } catch (error) {
                console.error('Failed to load categories:', error);
            }
        },
        async createProduct() {
            try {
                // Convert itemVes to boolean
                const formData = new FormData();
                for (const key in this.productForm) {
                    if (key === 'itemVes' || key === 'single_price') {
                        // Convert itemVes to boolean before appending
                        formData.append(key, this.productForm[key] === 'true'); // Ensure it's a boolean
                    } else {
                        formData.append(key, this.productForm[key]);
                    }
                }



                await marketProductService.createProduct(this.selectedMarketId, formData);
                this.loadProducts();
                this.closeDialog();
            } catch (error) {
                console.error('Failed to create product:', error);
            }
        },


        async updateProduct() {
            try {
                const formData = new FormData();
                for (const key in this.productForm) {
                    if (key === 'itemVes' || key === 'single_price') {
                        formData.append(key, this.productForm[key].toString());
                    } else {
                        formData.append(key, this.productForm[key]);
                    }
                }

                await marketProductService.updateProduct(this.selectedMarketId, this.productForm.id, formData);
                this.loadProducts();
                this.closeDialog();
            } catch (error) {
                console.error('Failed to update product:', error);
            }
        },
        openDeleteDialog(item) {
            this.itemToDelete = item;
            this.deleteDialog = true;
        },
        closeDeleteDialog() {
            this.deleteDialog = false;
        },
        confirmDelete() {
            this.deleteProduct(this.itemToDelete)
            console.log('تم حذف العنصر:', this.itemToDelete);

            this.deleteDialog = false;
        },
        async deleteProduct(productId) {
            try {
                await marketProductService.deleteProduct(this.selectedMarketId, productId);
                this.loadProducts();
            } catch (error) {
                console.error('Failed to delete product:', error);
            }
        },
        closeDialog() {
            this.addProductDialog = false;
            this.editMode = false;
            this.productForm = {
                categorie_id: null,
                name_en: '',
                name_ar: '',
                name_ku: '',
                description_en: '',
                description_ar: '',
                description_ku: '',
                image: null,
                single_price: true,
                price: '',
                price_s: '',
                price_m: '',
                price_l: '',
                itemVes: true
            };
        },
        async handleImageUpload(event) {
            const file = event.target.files[0];
            if (file) {
                this.isUploading = true;
                try {
                    const response = await fileUploadService.uploadFile(this.selectedMarketId, file);
                    this.productForm.image = response.files[0].path;
                } catch (error) {
                    console.error('Failed to upload image:', error);
                } finally {
                    this.isUploading = false;
                }
            }
        },
        filterProductsByCategory() {
            if (this.FilterProductBycategories === null) {
                this.filteredProducts = this.products; // عرض جميع المنتجات
            } else {
                this.filteredProducts = this.products.filter(product => product.categorie_id === this.FilterProductBycategories);
            }
        },
        async updateOrder() {
            console.log('Order updated:', this.products);
        },
        onDragEnd(event) {
            // قم بتحديث الخادم أو الحالة بعد سحب وإفلات العناصر
            console.log("تم سحب العنصر:", event.item);
            // هنا يمكنك تحديث البيانات على الخادم إذا لزم الأمر
        },
        log(event) {
            console.log("event")
            console.log(event)
        },

        async moveUp(productId) {
            const index = this.products.findIndex(product => product.id === productId);
            if (index > 0) {
                // تبادل المنتج مع المنتج الذي يسبقه
                const temp = this.products[index];
                this.products[index] = this.products[index - 1];
                this.products[index - 1] = temp;

                // تحديث ترتيب المنتجات المحدث
                await this.updateProductSorts();
            }
        },

        async moveDown(productId) {
            const index = this.products.findIndex(product => product.id === productId);
            if (index < this.products.length - 1) {
                // تبادل المنتج مع المنتج الذي يليه
                const temp = this.products[index];
                this.products[index] = this.products[index + 1];
                this.products[index + 1] = temp;

                // تحديث ترتيب المنتجات المحدث
                await this.updateProductSorts();
            }
        },

        async updateProductSorts() {
            const marketId = this.selectedMarketId; 

           
            const productData = 
         this.products.map((product, index) => ({
            id: product.id,
            sort_order:  index + 1 
        }))
    ;

            try {
                console.log(productData);

                
                await marketProductService.updateProductSort(marketId, productData);
            } catch (error) {
                console.error("Error updating product sort:", error);
                
            }
        }

    },
    async created() {
        try {
            await this.$store.dispatch('fetchUser');
            const user = this.getUser;
            if (user && user.marketId) {
                this.selectedMarketId = user.marketId;
                await this.loadProducts();
            } else if (this.isAdmin) {
                await this.fetchAllMarkets();
            } else {
                console.log('User does not have permission to manage Products.');
            }
        } catch (error) {
            console.error('Failed to initialize component:', error);
        }
    },


    watch: {
        selectedMarketId(newId) {
            if (newId) {
                this.loadProducts();
                this.loadCategories();
            }
        },
        FilterProductBycategories() {
            this.filterProductsByCategory();
        }
    },
};
</script>

<style scoped>
.checkbox-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.checkbox-group>.v-input--selection-controls__ripple {
    flex: 1 1 auto;
}

.draggable {
    cursor: move;
}
</style>