import axios from 'axios';
import store from '@/store';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL || 'http://localhost:3000',
  headers: {
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(config => {
    const token = store.state.token || localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  });

export default {
  getSliders(marketId) {
    return apiClient.get(`/markets/${marketId}/slider`);
  },
  getSlider(marketId, sliderId) {
    return apiClient.get(`/markets/${marketId}/slider/${sliderId}`);
  },
  createSlider(marketId, data) {
    return apiClient.post(`/markets/${marketId}/slider`, data);
  },
  updateSlider(marketId, sliderId, data) {
    return apiClient.patch(`/markets/${marketId}/slider/${sliderId}`, data);
  },
  deleteSlider(marketId, sliderId) {
    return apiClient.delete(`/markets/${marketId}/slider/${sliderId}`);
  },
};
