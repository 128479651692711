<template>
    <v-container>
        <v-row justify="center">
            <v-col cols="12" md="8">
                <template v-if="isAdmin">
                    <h2>{{ $t('selectMarket') }}</h2>
                    <v-select v-model="selectedMarketId" :items="markets" item-title="info.name" item-value="id"
                        :label="$t('pleaseSelectAMarket')" @change="loadCategories" outlined></v-select>
                </template>
                <v-container>
                    <template v-if="selectedMarketId || isAdmin">
                        <h2>{{ $t('manageCategories') }} </h2>
                        <v-btn @click="addCategoryDialog = true" color="primary" class="mb-4">{{ $t('addCategory')
                            }}</v-btn>

                        <v-row>
                            <v-data-table :headers="headers" :items="categories" item-key="id">
                                <template v-slot:item="props">
                                    <tr>
                                        <td>{{ props.item.id }}</td>
                                        <td>
                                            <v-avatar>
                                                <v-img :src="apiUrl + props.item.image" size="x-large"></v-img>
                                            </v-avatar>
                                        </td>
                                        <td>{{ props.item.name_en }}</td>
                                        <td>{{ props.item.name_ar }}</td>
                                        <td>{{ props.item.name_ku }}</td>
                                      <td> {{ props.item.productCount }} </td> 
                                        <td>
                                            <v-icon class="me-2" size="small"
                                                @click="editCategory(props.item)">mdi-pencil</v-icon>
                                            <v-icon color="error" size="small"
                                                @click="deleteCategory(props.item.id)">mdi-delete</v-icon>
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-row>
                    </template>
                    <template v-else>
                        <p v-if="!isAdmin">You do not have permission to manage categories.</p>
                        <p v-else>Loading...</p>
                    </template>
                </v-container>
            </v-col>
        </v-row>

        <!-- Add/Edit Category Dialog -->
        <v-dialog v-model="addCategoryDialog" persistent max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">{{ editMode ? $t('editCategory') : $t('addCategory') }}</span>
                </v-card-title>
                <v-card-text>
                    <v-form @submit.prevent="editMode ? updateCategory() : createCategory()">
                        <v-text-field v-model="categoryForm.name_en" :label="$t('nameEnglish')" required></v-text-field>
                        <v-text-field v-model="categoryForm.name_ar" :label="$t('nameArabic')" required></v-text-field>
                        <v-text-field v-model="categoryForm.name_ku" :label="$t('nameKurdish')" required></v-text-field>
                        <v-file-input :label="$t('categoryImage')" accept="image/*" prepend-icon="mdi-image"
                            @input="handleImageUpload">

                        </v-file-input>



                        <v-btn class="mx-2" type="submit" color="primary">{{ editMode ? $t('update') : $t('create')
                            }}</v-btn>
                        <v-btn class="mx-2" @click="closeDialog">{{ $t('cancel') }}</v-btn>
                        <v-progress-circular v-if="isUploading" indeterminate color="primary">
                        </v-progress-circular>
                    </v-form>

                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import marketCategorieService from '@/utils/marketcategorie';
import marketProductService from '@/utils/marketproduct';
import fileUploadService from '@/utils/fileupload';

export default {
    data() {
        return {
            selectedMarketId: null,
            categories: [],
            addCategoryDialog: false,
            editMode: false,
            categoryForm: {
                name_en: '',
                name_ar: '',
                name_ku: '',
                image: null
            },
            headers: [
                { title: this.$t('id'), value: 'id' },
                { title: this.$t('image'), value: 'image' },
                { title: this.$t('nameEnglish'), value: 'name_en' },
                { title: this.$t('nameArabic'), value: 'name_ar' },
                { title: this.$t('nameKurdish'), value: 'name_ku' },
                { title: this.$t('productCount'), value: 'productCount' },
                { title: this.$t('actions'), value: 'actions' }
            ],
       
            successSnackbar: false,
            errorSnackbar: false,
            isUploading: false
        };
    },
    computed: {
        ...mapGetters(['getMarketInfo', 'getUser', 'getMarkets']),
        marketInfo() {
            return this.getMarketInfo;
        },
        isAdmin() {
            return this.getUser && this.getUser.role === 'admin';
        },
        markets() {
            return this.getMarkets.map(market => ({
                ...market,
                'info.name': market.info.name // Ensure the structure is correct
            }));
        },
        apiUrl() {
            return process.env.VUE_APP_API_URL + '/';
        },
    },
    methods: {
        ...mapActions(['fetchUser', 'fetchAllMarkets']),
        async loadCategories() {
            try {
                const response = await marketCategorieService.getCategories(this.selectedMarketId);
                this.categories = response.data;
                await this.loadProducts();
                this.categories.forEach(category => {
                    category.productCount = this.products.filter(product => product.categorie_id === category.id).length;
                });

            } catch (error) {
                console.error('Failed to load categories:', error);
            }
        },
        async loadProducts() {
            try {
                const response = await marketProductService.getProducts(this.selectedMarketId);
                this.products = response.data;
            } catch (error) {
                console.error('Failed to fetch products:', error);
            }
        },
        addCategory() {
            this.editMode = false;
            this.categoryForm = {
                name_en: '',
                name_ar: '',
                name_ku: '',
                image: null
            };
            this.addCategoryDialog = true;

        },
        editCategory(category) {
            this.editMode = true;
            this.categoryForm = { ...category };
            this.addCategoryDialog = true;
        },
        async createCategory() {
            try {
                const formData = new FormData();
                for (const key in this.categoryForm) {
                    formData.append(key, this.categoryForm[key]);
                }
                await marketCategorieService.createCategory(this.selectedMarketId, formData);
                this.loadCategories();
                this.closeDialog();
            } catch (error) {
                console.error('Failed to create category:', error);
            }
        },
        async updateCategory() {
            try {
                const formData = new FormData();
                for (const key in this.categoryForm) {
                    formData.append(key, this.categoryForm[key]);
                }
                await marketCategorieService.updateCategory(this.selectedMarketId, this.categoryForm.id, formData);
                this.loadCategories();
                this.closeDialog();
            } catch (error) {
                console.error('Failed to update category:', error);
            }
        },
        async deleteCategory(categoryId) {
            try {
                await marketCategorieService.deleteCategory(this.selectedMarketId, categoryId);
                this.loadCategories();
            } catch (error) {
                console.error('Failed to delete category:', error);
            }
        },
        closeDialog() {
            this.addCategoryDialog = false;
            this.editMode = false;
            this.categoryForm = {
                name_en: '',
                name_ar: '',
                name_ku: '',
                image: null
            };
        },
        async handleImageUpload(event) {
            const file = event.target.files[0];
            if (file) {
                this.isUploading = true; // ابدأ التحميل
                try {
                    const response = await fileUploadService.uploadFile(this.selectedMarketId, file);
                    this.categoryForm.image = response.files[0].path; // احفظ مسار الملف

                } catch (error) {
                    console.error('Failed to upload image:', error);
                } finally {
                    this.isUploading = false; // انتهاء التحميل
                }
            }
        }
    },
    async created() {
        try {
            await this.$store.dispatch('fetchUser');
            const user = this.getUser;
            if (user && user.marketId) {
                this.selectedMarketId = user.marketId;
                await this.loadCategories();
            } else if (this.isAdmin) {
                await this.fetchAllMarkets();
            } else {
                console.log('User does not have permission to manage categories.');
            }
        } catch (error) {
            console.error('Failed to initialize component:', error);
        }
    },
    watch: {
        selectedMarketId(newId) {
            if (newId) {
                this.loadCategories();
            }
        },
    }
};
</script>

<style scoped>
.checkbox-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.checkbox-group>.v-input--selection-controls__ripple {
    flex: 1 1 auto;
}
</style>