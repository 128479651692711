import HomeView from '../views/HomeView.vue'

const menuRoutes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // Lazy-loaded route
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

export default menuRoutes;
