import axios from 'axios';
import store from '@/store';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL || 'http://localhost:3000',
  headers: {
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(config => {
    const token = store.state.token || localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  });
export default {
  getProducts(marketId) {
    return apiClient.get(`/markets/${marketId}/products`);
  },
  getProduct(marketId, productId) {
    return apiClient.get(`/markets/${marketId}/products/${productId}`);
  },
  createProduct(marketId, data) {  
    console.log('Token:', apiClient.defaults.headers.common['Authorization']);
    console.log('Market ID:', marketId);
    console.log('Data:', data);
    return apiClient.post(`/markets/${marketId}/products`, data);
  },
  updateProduct(marketId, productId, data) {
    return apiClient.patch(`/markets/${marketId}/products/${productId}`, data);
  },
  deleteProduct(marketId, productId) {
    return apiClient.delete(`/markets/${marketId}/products/${productId}`);
  },
  getCategories(marketId) {
    return apiClient.get(`/markets/${marketId}/categories`);
  },
   updateProductSort(marketId, productData) {
   
    return apiClient.patch(`/markets/${marketId}/products/sort`, productData);
}
};



