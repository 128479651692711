import axios from 'axios';
import store from '@/store';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL || 'http://localhost:3000',
  headers: {
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(config => {
    const token = store.state.token || localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  });


export default {
  getCategories(marketId) {
    return apiClient.get(`/markets/${marketId}/categories`);
  },
  createCategory(marketId, data) {
    return apiClient.post(`/markets/${marketId}/categories`, data);
  },
  updateCategory(marketId, categoryId, data) {
    return apiClient.patch(`/markets/${marketId}/categories/${categoryId}`, data);
  },
  deleteCategory(marketId, categoryId) {
    return apiClient.delete(`/markets/${marketId}/categories/${categoryId}`);
  },
};
