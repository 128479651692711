import { createStore } from 'vuex';
import auth from '@/utils/auth';
import marketInfoApi from '@/utils/marketinfo'; // Renamed to avoid confusion with Vuex state

export default createStore({
  state: {
    token:  localStorage.getItem('token') || '',
    user: null,
    darkTheme: false,
    marketInfo: null,
    markets: [],
    selectedMarketId: null,

  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },
    CLEAR_TOKEN(state) {
      state.token = '';
    },
    SET_USER(state, user) {
      state.user = user;
    },
    CLEAR_USER(state) {
      state.user = null;
    },
    SET_MARKET_INFO(state, marketInfo) {
      state.marketInfo = marketInfo;
    },
    CLEAR_MARKET_INFO(state) {
      state.marketInfo = null;
    },
    SET_MARKETS(state, markets) {
      state.markets = markets;
    },
    CLEAR_MARKETS(state) {
      state.markets = [];
    },
    toggleTheme(state) {
      state.darkTheme = !state.darkTheme;
    },
    setSelectedMarketId(state, marketId) {
        state.selectedMarketId = marketId;
      },
  },
  actions: {
    async performLogin({ commit }, credentials) {
      try {
        const response = await auth.login(credentials);
        const token = response.data.token;
        localStorage.setItem('token', token);
        commit('SET_TOKEN', token);
        await this.dispatch('fetchUser');
      } catch (error) {
        console.error('Login failed:', error);
        throw error;
      }
    },
    logout({ commit }) {
      localStorage.removeItem('token');
      commit('CLEAR_TOKEN');
      commit('CLEAR_USER');
      commit('CLEAR_MARKET_INFO');
      commit('CLEAR_MARKETS');
    },
    async fetchUser({ commit }) {
      try {
        const response = await auth.getCurrentUser();
        commit('SET_USER', response.data);
      } catch (error) {
        console.error('Failed to fetch user:', error);
        throw error;
      }
    },
    async fetchMarketInfo({ commit }, marketId) {
      try {
        const response = await marketInfoApi.getMarketInfo(marketId);
        commit('SET_MARKET_INFO', response.data);
      } catch (error) {
        console.error('Failed to fetch market info:', error);
        throw error;
      }
    },
    async updateMarketInfo({ commit }, { marketId, data }) {
        try {          
          // إجراء طلب API لتحديث البيانات في الخلفية
          await marketInfoApi.updateMarketInfo(marketId, data);
          
          // تحديث الحالة المحلية بالبيانات المحدثة
          commit('SET_MARKET_INFO', data);
        } catch (error) {
          console.error('Failed to update market info:', error);
          throw error;
        }
      },
      async fetchAllMarkets({ commit }) {
        try {
          const response = await marketInfoApi.getAllMarkets();
          commit('SET_MARKETS', response.data);
        } catch (error) {
          console.error('Failed to fetch all markets:', error);
          throw error;
        }
      },
      updateSelectedMarketId({ commit }, marketId) {
        commit('setSelectedMarketId', marketId);
      },
  },
  getters: {
    isAuthenticated: state => !!state.token,
    getUser: state => state.user,
    isDarkTheme: state => state.darkTheme,
    getMarketInfo: state => state.marketInfo,
    getMarkets: state => state.markets,
    getSelectedMarketId: state => state.selectedMarketId,

  }
});