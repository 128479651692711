<template>
    <v-app id="app">
        <v-main>
            <v-locale-provider>
                <router-view />
            </v-locale-provider>
        </v-main>
    </v-app>
</template>

<script>

</script>

<style></style>