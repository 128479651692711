<template>
    <v-container>
        <div>
            <v-row class="justify-space-between mb-5">
                <v-col cols="12" sm="12" md="4" lg="4" xl="4" xxl="4">
                    <div class="text-h5 pa-2">All Markets</div>
                </v-col>
                <v-col cols="12" sm="12" md="4" lg="4" xl="4" xxl="4">
                    <v-text-field v-model="search" append-inner-icon="mdi-magnify" density="compact" label="Search"
                        variant="solo" hide-details single-line ></v-text-field>
                </v-col>
            </v-row>
            <v-spacer></v-spacer>
            <v-btn color="primary ma-2" @click="openCreateDialog" prepend-icon="mdi-store-plus-outline">Create New Market</v-btn>

            <v-data-table :headers="headers" :items="filteredMarkets" item-key="id">
                <template v-slot:item="props">
                    <tr>
                        <td>
                            <v-avatar>
                                <v-img :src="apiUrl + props.item.info.logo" size="x-large"></v-img>
                            </v-avatar>
                        </td>
                        <td>{{ props.item.id }}</td>
                        <td>{{ props.item.info.name }}</td>
                        <td>
                            <v-icon class="me-2" size="small" @click="editMarket(props.item)">
                                mdi-pencil
                            </v-icon>
                            <v-icon color="error" size="small" @click="confirmDeleteMarket(props.item)">
                                mdi-delete
                            </v-icon>
                        </td>
                    </tr>
                </template>
            </v-data-table>

            <!-- Edit Market Dialog -->
            <v-dialog v-model="editDialog" max-width="500px">
                <v-card>
                    <v-card-title>
                        <span v-if="isEditMode">Edit Market</span>
                        <span v-else>Create New Market</span>
                    </v-card-title>
                    <v-card-text>
                        <v-form ref="form">
                            <v-text-field v-model="marketForm.info.name" label="Name" prepend-inner-icon="mdi-store"></v-text-field>
                            <div class="mb-5" v-if="isEditMode">
                                <input type="file" @change="handleLogoUpload" ref="logoUploader" style="display: none;" />
                                <v-btn  @click="triggerLogoUpload" class="me-2 text-none" prepend-icon="mdi-plus" variant="outlined">
                                    Change Logo
                                </v-btn>
                                <input type="file" @change="handleBackgroundUpload" ref="backgroundUploader" style="display: none;" />
                                <v-btn @click="triggerBackgroundUpload" class="me-2 text-none" prepend-icon="mdi-plus" variant="outlined">
                                    Change Background
                                </v-btn>
                            </div>
                            
                            <v-textarea v-model="marketForm.info.description" label="Description" prepend-inner-icon="mdi-information-slab-circle-outline"></v-textarea>
                            <v-text-field v-model="marketForm.info.social_links.facebook" label="Facebook" prepend-inner-icon="mdi-facebook"></v-text-field>
                            <v-text-field v-model="marketForm.info.social_links.instagram" label="Instagram" prepend-inner-icon="mdi-instagram"></v-text-field>
                            <v-text-field v-model="marketForm.info.social_links.telegram" label="Telegram" prepend-inner-icon="mdi-send"></v-text-field>
                            <v-text-field v-model="marketForm.info.social_links.whatsapp" label="WhatsApp" prepend-inner-icon="mdi-whatsapp"></v-text-field>
                            <p><v-icon>mdi-translate-variant</v-icon>  Select Language </p>
                            <div class="checkbox-group" >
                                <v-checkbox v-for="language in availableLanguages" :key="language"
                                    v-model="marketForm.info.languages" :label="language" :value="language"
                                    inline></v-checkbox>
                            </div>

                            <p><v-icon>mdi-wallet</v-icon>  Select Currency</p>
                            <div class="checkbox-group">
                                <v-checkbox v-for="currency in currencyOptions" :key="currency.id" v-model="marketForm.info.currency" :label="currency.name" :value="currency.id" inline></v-checkbox>
                            </div>
                           
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="primary" @click="saveMarket">{{ isEditMode ? 'Save' : 'Create' }}</v-btn>
                        <v-btn @click="cancelEdit">Cancel</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- Delete Market Confirmation Dialog -->
            <v-dialog v-model="deleteDialog" max-width="500px">
                <v-card>
                    <v-card-title>Confirm Delete</v-card-title>
                    <v-card-text>Are you sure you want to delete this market?</v-card-text>
                    <v-card-actions>
                        <v-btn color="error" @click="deleteMarket">Delete</v-btn>
                        <v-btn @click="cancelDelete">Cancel</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import marketService from '@/utils/markets';
import fileUploadService from '@/utils/fileupload';

export default {
    name: 'allMarkets',
    data() {
        return {
            headers: [
                { title: 'Logo', value: 'info.logo' },
                { title: 'ID', value: 'id' },
                { title: 'Name', value: 'info.name' },
                { title: 'Actions', value: 'actions' },
            ],
            markets: [],
            editDialog: false,
            availableLanguages: ['ar', 'en', 'ckb'],
            deleteDialog: false,
            marketToDelete: null,
            marketForm: {
                info: {
                    name: '',
                    logo: '',
                    background_image: '',
                    languages: ["ar", "en", "ckb"],
                    currency: ["USD", "IQD"],
                    description: '',
                    social_links: {
                        facebook: '',
                        instagram: '',
                        telegram: '',
                        whatsapp: '',
                    },
                },
            },
            search: '',
            isEditMode: false,
        };
    },
    computed: {
        ...mapGetters(['getUser', 'allMarkets']),
        isAdmin() {
            return this.getUser && this.getUser.role === 'admin';
        },
        apiUrl() {
            return process.env.VUE_APP_API_URL + '/';
        },
        currencyOptions() {
            return [
                { id: 'IQD', name: 'IQD' },
                { id: 'USD', name: 'USD' }
            ];
        },
        filteredMarkets() {
            if (!this.search) {
                return this.markets;
            }
            return this.markets.filter((market) => {
                return (
                    market.info.name.toLowerCase().includes(this.search.toLowerCase()) ||
                    String(market.info.id).includes(this.search)
                );
            });
        },
    },
    methods: {
        ...mapActions(['fetchAllMarkets']),
        openCreateDialog() {
            this.isEditMode = false;
            this.resetForm();
            this.editDialog = true;
        },
        triggerLogoUpload() {
            this.$refs.logoUploader.click();
        },
        triggerBackgroundUpload() {
            this.$refs.backgroundUploader.click();
        },
        async handleLogoUpload(event) {
            const file = event.target.files[0];
            if (file) {
                try {
                    const response = await fileUploadService.uploadFile(this.marketForm.id, file);
                    this.marketForm.info.logo = response.files[0].path;
                } catch (error) {
                    console.error('Failed to upload logo:', error);
                }
            }
        },
        async handleBackgroundUpload(event) {
            const file = event.target.files[0];
            if (file) {
                try {
                    const response = await fileUploadService.uploadFile(this.marketForm.id, file);
                    this.marketForm.info.background_image = response.files[0].path;
                } catch (error) {
                    console.error('Failed to upload background image:', error);
                }
            }
        },
        async saveMarket() {
            try {
                if (this.isEditMode) {
                    await marketService.updateMarket(this.marketForm.id, this.marketForm);
                } else {
                    console.log(this.market);
                    await marketService.createMarket(this.marketForm);
                }
                this.editDialog = false;
                this.fetchMarkets();
            } catch (error) {
                console.error('Failed to save market:', error);
            }
        },
        editMarket(market) {
            this.marketForm = { ...market };
            this.isEditMode = true;
            this.editDialog = true;
        },
        cancelEdit() {
            this.editDialog = false;
        },
        confirmDeleteMarket(market) {
            this.marketToDelete = market;
            this.deleteDialog = true;
        },
        async deleteMarket() {
            try {
                await marketService.deleteMarket(this.marketToDelete.id);
                this.deleteDialog = false;
                this.fetchMarkets();
            } catch (error) {
                console.error('Failed to delete market:', error);
            }
        },
        cancelDelete() {
            this.deleteDialog = false;
        },
        async fetchMarkets() {
            try {
                const response = await marketService.getAllMarkets();
                this.markets = response.data;
            } catch (error) {
                console.error('Failed to load markets:', error);
            }
        },
        resetForm() {
            this.marketForm = {
                info: {
                    name: '',
                    logo: '',
                    background_image: '',
                    languages: ["ar", "en", "ckb"],
                    currency: ["USD", "IQD"],
                    description: '',
                    social_links: {
                        facebook: '',
                        instagram: '',
                        telegram: '',
                        whatsapp: '',
                    },
                },
            };
        },
    },
    async created() {
        try {
            await this.$store.dispatch('fetchUser');
            const user = this.$store.getters.getUser;
            if (user && user.role === 'admin') {
                await this.fetchMarkets();
            } else {
                console.log('User is not an admin. Cannot fetch markets.');
            }
        } catch (error) {
            console.error('Failed to initialize component:', error);
        }
    },
};
</script>

<style scoped>
.card {
    margin-top: 20px;
}
.checkbox-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.checkbox-group>.v-input--selection-controls__ripple {
    flex: 1 1 auto;
}
</style>
