// src/router/index.js
import { createRouter, createWebHashHistory } from 'vue-router'
import menuRoutes from './menuRoutes'
import dashboardRoutes from './dashboardRouter'

const routes = [
  ...menuRoutes,
  ...dashboardRoutes
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
      const token = localStorage.getItem('token'); 
      if (!token) {
        next({ name: 'dashboard-login' });
      } else {
        next();
      }
    } else {
      next();
    }
    
  });
  
export default router
