import axios from 'axios';
import store from '@/store';

const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL || 'http://localhost:3000',
  headers: {
    'Content-Type': 'application/json'
  }
});

apiClient.interceptors.request.use(config => {
    const token = store.state.token || localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  });

export default {
  getMarketInfo(marketId) {
    return apiClient.get(`/markets/${marketId}/info`);
  },
  updateMarketInfo(marketId, data) {
    // console.log("this.token",token);
    
    return apiClient.patch(`/markets/${marketId}/info`, data);
},
  getAllMarkets() {
    return apiClient.get('/markets');
  }
};
